
import Vue from "vue";
import { mapGetters } from "vuex";
import Socket from "@/services/Socket";

export default Vue.extend({
  name: "AgendaTimezoneModal",
  props: {
    profile: Object
  },
  data() {
    return {
      loading: false,
      data: {
        timezone_id: ""
      },
      errors: {} as any,
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      Deselect: {
        render: (createElement: any) =>
          createElement("b-icon", {
            attrs: {
              icon: "X"
            }
          })
      }
    };
  },
  computed: {
    ...mapGetters({
      timezones: "helper/getTimezones"
    })
  },
  mounted() {
    this.data.timezone_id = this.profile?.timezone_id;

    Socket().on(`agenda.user.${this.profile.id}`, data => {
      if (data.currentUserId == this.profile.id) {
        return;
      }
      this.$emit("refresh");
    });

    this.$store.dispatch("helper/GET_TIMEZONES");
  },
  methods: {
    onUpdate() {
      this.loading = true;

      this.$store
        .dispatch("profile/UPDATE_TIMEZONE", this.data)
        .then(() => {
          this.loading = false;
          this.$store.dispatch("profile/GET_PROFILE");
          this.$emit("refresh");
          this.close();
        })
        .catch(error => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    close() {
      this.$bvModal.hide("agenda-timezone");
    }
  }
});
